<template>
	<S2SCard title="Verify KYC" class="mx-3">
		<v-row>
			<v-col cols="12" md="6">
				<v-text-field
					v-model="filterKycByEmail"
					label="Filter by Email"
					clearable
					append-icon="mdi-magnify"
					@click:append="filterKyc"
					@keypress.enter="filterKyc"
				></v-text-field>
			</v-col>
			<v-col cols="12" md="6">
				<v-select
					:label="`Filter by Status - (${kycUsersCount})`"
					v-model="verificationStatus"
					:items="statusItems"
					@change="filterKyc"
					clearable
				></v-select>
			</v-col>
		</v-row>

		<v-data-table
			:headers="header"
			:items="kycUsers"
			class="elevation-1"
			:options.sync="pagination"
			:server-items-length="totalItems"
			:footer-props="{ 'items-per-page-options': [15, 25, 50, 100] }"
		>
			<template v-slot:item.idVerificationStatus="{ item }">
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-icon v-on="on" :class="getStatusColour(item)">{{ getStatusIcon(item) }}</v-icon>
					</template>
					<span>{{ item.idVerificationStatus.name === "Unverifiable" ? "Not Submitted" : item.idVerificationStatus.name }}</span>
				</v-tooltip>
			</template>

			<template v-slot:item.edit="{ item }">
				<v-tooltip top>
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on" @click="edit(item.userId)">
							<v-icon>mdi-pencil</v-icon>
						</v-btn>
					</template>
					<span>Edit</span>
				</v-tooltip>

				<v-tooltip top v-if="item.idVerificationStatus.key !== 'unverifiable'">
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on" @click="view(item)">
							<v-icon>mdi-eye</v-icon>
						</v-btn>
					</template>
					<span>View</span>
				</v-tooltip>
			</template>
		</v-data-table>

		<!-- Verify -->
		<SimpleDialog v-if="selectedKyc" v-model="verified" title="Are you sure?" class="simple-dialog">
			<h3 class="mt-5">You are about to verify {{ selectedKyc.firstName }} {{ selectedKyc.lastName }}</h3>
			<div slot="button-container">
				<v-btn @click="verified = false" class="mr-2">No</v-btn>
				<v-btn @click="verify(selectedKyc)" color="accent">Yes</v-btn>
			</div>
		</SimpleDialog>

		<!-- // Deny -->
		<SimpleDialog
			v-if="selectedKyc"
			v-model="denied"
			:title="`${selectedKyc.firstName} ${selectedKyc.lastName}' denied verification`"
			class="simple-dialog"
		>
			<div class="v-label mt-5">Denied Section</div>
			<v-form ref="denyForm">
				<v-row>
					<v-col><v-checkbox v-model="deniedSection" label="ID number" value="ID number"> </v-checkbox></v-col>
					<v-col><v-checkbox v-model="deniedSection" label="ID Photo" value="ID Photo"> </v-checkbox></v-col>
					<v-col><v-checkbox v-model="deniedSection" label="Selfie" value="Selfie"> </v-checkbox></v-col>
				</v-row>

				<v-textarea label="Reason" auto-grow rows="5" v-model="deniedReason" :rules="[v => !!v || 'Reason is required']"></v-textarea>
			</v-form>
			<div slot="button-container">
				<v-btn @click="denied = false" class="mr-2">Cancel</v-btn>
				<v-btn @click="submitDenial(selectedKyc)" color="accent">Submit</v-btn>
			</div>
		</SimpleDialog>

		<!-- // VIEW -->
		<v-dialog v-model="viewKyc" v-if="selectedKyc" width="700">
			<v-card>
				<v-card-title class="headline grey lighten-2" primary-title> KYC: {{ selectedKyc.userName }} </v-card-title>

				<v-card-text>
					<v-row class="pt-3">
						<v-col cols="12" md="6">
							<v-text-field v-model="selectedKyc.firstName" label="Name" readonly filled hide-details></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field v-model="selectedKyc.lastName" label="Surname" readonly filled hide-details></v-text-field>
						</v-col>
					</v-row>

					<v-row>
						<v-col cols="12" md="6">
							<v-text-field v-model="selectedKyc.email" label="Email" readonly filled hide-details></v-text-field>
						</v-col>
						<v-col cols="12" md="6" v-if="!isRejected">
							<v-text-field v-model="selectedKyc.idNumber.idNumber" :label="label" filled hide-details readonly></v-text-field>
						</v-col>
					</v-row>

					<v-row v-if="!isRejected">
						<v-col cols="12" md="6">
							<v-text-field v-model="selectedKyc.dob" label="Date Of Birth" readonly filled hide-details></v-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<v-text-field v-model="selectedKyc.gender" label="Gender" filled hide-details readonly></v-text-field>
						</v-col>
					</v-row>

					<v-row v-if="!isRejected">
						<v-col cols="12" md="6">
							<h3>Identification</h3>

							<div class="text-right">
								<v-tooltip top>
									<template v-slot:activator="{ on }">
										<v-btn icon v-on="on" @click="zoomId = !zoomId" color="accent">
											<v-icon>mdi-plus</v-icon>
										</v-btn>
									</template>
									<span>Zoom</span>
								</v-tooltip>
							</div>

							<!-- PDF -->
							<div v-if="selectedKyc.idImagePDF">
								<iframe :src="selectedKyc.idImageUrl" frameborder="0" width="100%" height="300"></iframe>
							</div>

							<!-- IMAGE -->
							<div v-else>
								<v-img :src="selectedKyc.idImageUrl" :lazy-src="selectedKyc.idImageUrl" max-width="500" max-height="300">
									<template v-slot:placeholder>
										<v-row class="fill-height ma-0" align="center" justify="center">
											<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
										</v-row>
									</template>
								</v-img>
							</div>

							<v-dialog v-model="zoomId" v-if="selectedKyc">
								<v-card>
									<v-card-title>
										<v-spacer></v-spacer>
										<v-tooltip top>
											<template v-slot:activator="{ on }">
												<v-btn icon v-on="on" @click="zoomId = !zoomId" color="accent">
													<v-icon>mdi-close</v-icon>
												</v-btn>
											</template>
											<span>Close</span>
										</v-tooltip>
									</v-card-title>

									<!-- PDF -->
									<div v-if="selectedKyc.idImagePDF">
										<iframe class="zoomPDF" :src="selectedKyc.idImageUrl" frameborder="0" width="100%"></iframe>
									</div>

									<!-- IMAGE -->
									<div v-else>
										<v-img :src="selectedKyc.idImageUrl" :lazy-src="selectedKyc.idImageUrl" style="margin: auto">
											<template v-slot:placeholder>
												<v-row class="fill-height ma-0" align="center" justify="center">
													<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
												</v-row>
											</template>
										</v-img>
									</div>
								</v-card>
							</v-dialog>
						</v-col>

						<v-col cols="12" md="6">
							<h3>Selfie</h3>
							<div class="text-right">
								<v-tooltip top>
									<template v-slot:activator="{ on }">
										<v-btn icon v-on="on" @click="zoomSelfie = !zoomSelfie" color="accent">
											<v-icon>mdi-plus</v-icon>
										</v-btn>
									</template>
									<span>Zoom</span>
								</v-tooltip>
							</div>

							<!-- PDF -->
							<div v-if="selectedKyc.selfieImagePDF">
								<iframe :src="selectedKyc.selfieImageUrl" frameborder="0" width="100%" height="300"></iframe>
							</div>

							<!-- IMAGE -->
							<div v-else>
								<v-img :src="selectedKyc.selfieImageUrl" :lazy-src="selectedKyc.selfieImageUrl" max-width="500" max-height="300">
									<template v-slot:placeholder>
										<v-row class="fill-height ma-0" align="center" justify="center">
											<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
										</v-row>
									</template>
								</v-img>
							</div>

							<!-- ZOOM -->
							<v-dialog v-model="zoomSelfie" v-if="selectedKyc">
								<v-card>
									<v-card-title>
										<v-spacer></v-spacer>
										<v-tooltip top>
											<template v-slot:activator="{ on }">
												<v-btn icon v-on="on" @click="zoomSelfie = !zoomSelfie" color="accent">
													<v-icon>mdi-close</v-icon>
												</v-btn>
											</template>
											<span>Close</span>
										</v-tooltip>
									</v-card-title>

									<!-- PDF -->
									<div v-if="selectedKyc.selfieImagePDF">
										<iframe class="zoomPDF" :src="selectedKyc.selfieImageUrl" frameborder="0" width="100%"></iframe>
									</div>

									<!-- IMAGE -->
									<div v-else>
										<v-img :src="selectedKyc.selfieImageUrl" :lazy-src="selectedKyc.selfieImageUrl" style="margin: auto">
											<template v-slot:placeholder>
												<v-row class="fill-height ma-0" align="center" justify="center">
													<v-progress-circular indeterminate color="grey lighten-5"></v-progress-circular>
												</v-row>
											</template>
										</v-img>
									</div>
								</v-card>
							</v-dialog>
						</v-col>
					</v-row>
					<v-row v-if="isRejected">
						<v-col class="py-3">
							<v-divider class="pt-3"></v-divider>
							<h3>Rejected Reason</h3>
							<p>{{ selectedKyc.idVerificationStatus.reason }}</p>
						</v-col>
					</v-row>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-btn @click="viewKyc = false">Cancel</v-btn>
					<v-spacer></v-spacer>
					<div v-if="selectedKyc.idVerificationStatus.key == 'pending'">
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn icon v-on="on" @click="verified = true" color="green"><v-icon>mdi-check</v-icon></v-btn>
							</template>
							<span>Verify</span>
						</v-tooltip>
						<v-tooltip top>
							<template v-slot:activator="{ on }">
								<v-btn icon v-on="on" @click="deny(selectedKyc)" color="red"><v-icon>mdi-close</v-icon></v-btn>
							</template>
							<span>Deny</span>
						</v-tooltip>
					</div>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</S2SCard>
</template>

<script>
import SimpleDialog from "@/components/SimpleDialog.vue";

export default {
	name: "VerifyKYC",

	components: {
		SimpleDialog
	},

	computed: {
		kycUsers: function() {
			return this.$store.state.user.kycUsers;
		},
		totalItems: function() {
			return this.$store.state.user.kycUsersTotal;
		},
		filterKycByEmail: {
			get() {
				return this.$store.state.user.filterKycByEmail;
			},
			set(val) {
				this.$store.dispatch("user/filterKycByEmail", val);
			}
		},
		verificationStatus: {
			get() {
				return this.$store.state.user.verificationStatus;
			},
			set(val) {
				this.$store.dispatch("user/filterVerificationStatus", val);
			}
		}
	},

	watch: {
		pagination: {
			deep: true,
			immediate: true,
			handler(paging) {
				if ("groupBy" in paging) {
					this.$store.dispatch("user/getKYCUsers", paging);
				}
			}
		},
		selectedKyc: function(val) {
			if (val != null) {
				this.getLabel(val.idNumber.idType);
				this.isRejected = val.idVerificationStatus.key === "rejected" ? true : false;
			}
		},
		kycUsers: function(users) {
			if (users.length === this.totalItems) {
				this.kycUsersCount = users.length;
			} else {
				this.kycUsersCount = this.totalItems;
			}
		}
	},

	data: function() {
		return {
			header: [
				{
					text: "UID",
					value: "userId"
				},
				{
					text: "Verified Status",
					value: "idVerificationStatus"
				},
				{
					text: "First Name",
					value: "firstName"
				},
				{
					text: "Lastname",
					value: "lastName"
				},
				{
					text: "Edit",
					value: "edit"
				}
			],
			selectedKyc: null,
			isRejected: false,
			verified: false,
			denied: false,
			deniedSection: [],
			deniedReason: "",
			pagination: {
				page: 1,
				itemsPerPage: 15
			},
			zoomId: false,
			zoomSelfie: false,
			viewKyc: false,
			label: null,
			items: [
				{
					value: "rsa_id",
					text: "RSA ID"
				},
				{
					value: "passport",
					text: "Passport"
				},
				{
					value: "asylum",
					text: "Asylum"
				},
				{
					value: "other",
					text: "Other"
				}
			],
			kycUsersCount: 0,
			statusItems: [
				{
					value: "unverifiable",
					text: "Not Submitted"
				},
				{
					value: "pending",
					text: "Pending"
				},
				{
					value: "verified",
					text: "Verified"
				},
				{
					value: "rejected",
					text: "Rejected"
				}
			]
		};
	},

	mounted: function() {
		this.filterKyc();
	},

	methods: {
		edit(id) {
			this.$router.push({
				name: "update-kyc",
				params: { uid: id }
			});
		},
		async verify(user) {
			user.approved = true;
			const request = await this.$store.dispatch("user/verifyKYC", user);
			if (request.status === 200) {
				this.$store.dispatch("user/getKYCUsers", this.pagination);
				this.viewKyc = false;
				this.verified = false;
			}
		},
		deny(item) {
			this.denied = true;
			this.selectedKyc = item;
		},
		view(item) {
			this.selectedKyc = item;

			// if idNumber is null
			if (this.selectedKyc.idNumber == null) {
				this.selectedKyc.idNumber = { idNumber: null, idType: "rsa_id" };
			}

			this.selectedKyc.idImagePDF = item.idImage && item.idImage.contentType === "application/pdf" ? true : false;
			this.selectedKyc.idImageUrl = item.idImage && item.idImage.url ? `https://${item.idImage.url}` : null;

			this.selectedKyc.selfieImagePDF = item.selfieImage && item.selfieImage.contentType === "application/pdf" ? true : false;
			this.selectedKyc.selfieImageUrl = item.selfieImage && item.selfieImage.url ? `https://${item.selfieImage.url}` : null;

			this.viewKyc = true;
		},
		getLabel(type) {
			const label = this.items.filter(item => {
				return item.value === type;
			});
			this.label = type == "unverifiable" ? "Not Submitted" : label[0].text;
		},
		async submitDenial(user) {
			const form = this.$refs.denyForm;
			const valid = await form.validate();

			const reason = this.deniedSection.join("\n") + `\n\n${this.deniedReason}`;

			if (valid) {
				user.approved = false;
				user.reason = reason;
				const request = await this.$store.dispatch("user/verifyKYC", user);

				if (request.status == 200) {
					this.selectedKyc = null;
					this.deniedSection = [];
					this.deniedReason = "";
					this.denied = false;
					this.viewKyc = false;

					this.$store.dispatch("user/getKYCUsers", this.pagination);
				}
			}
		},

		getStatusIcon(item) {
			const status = item.idVerificationStatus.key;
			let icon = null;

			switch (status) {
				case "verified":
					icon = "mdi-shield-check";
					break;

				case "pending":
					icon = "mdi-account-clock";
					break;

				case "rejected":
					icon = "mdi-account-off";
					break;

				default:
					icon = "mdi-shield-alert-outline";
					break;
			}
			return icon;
		},

		getStatusColour(item) {
			const status = item.idVerificationStatus.key;
			let colour = null;

			switch (status) {
				case "verified":
					colour = "green--text";
					break;

				case "pending":
					colour = "blue--text";
					break;

				case "rejected":
					colour = "red--text";
					break;

				default:
					colour = "orange--text";
					break;
			}
			return colour;
		},

		filterKyc() {
			this.pagination = { page: 1, itemsPerPage: 15, email: this.filterKycByEmail, verificationStatus: this.verificationStatus };
		}
	}
};
</script>

<style scoped>
.zoomPDF {
	height: 80vh;
}
</style>
